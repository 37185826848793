import Axios from "axios";
import authHeader from "../../helpers/auth-header.js";
import { ATELIERS_API, EVENEMENTS_API } from "../../services/config.js";

export const FETCH_ATELIERS= "FETCH_ATELIERS";
export const FETCH_CURRENT_ATELIER= "FETCH_CURRENT_ATELIER";
export const ADD_ATELIER_SUCCESS= "ADD_ATELIER_SUCCESS";
export const ADD_ATELIER_FAIL= "ADD_ATELIER_FAIL";
export const ADD_ATELIER_FAIL_DESC= "ADD_ATELIER_FAIL_DESC";
export const UPDATE_ATELIER_SUCCESS= "UPDATE_ATELIER_SUCCESS";
export const UPDATE_ATELIER_FAIL= "UPDATE_ATELIER_FAIL";
export const UPDATE_ATELIER_FAIL_DESC= "UPDATE_ATELIER_FAIL_DESC";
export const DELETE_ATELIER= "DELETE_ATELIER";

export const getAteliers = (selectedEventId) => (dispatch) => {

  return Axios.get(EVENEMENTS_API + "/" + selectedEventId + "/ateliers").then(
    (data) => {
      
      dispatch({
        type: FETCH_ATELIERS,
        ateliers: data.data['hydra:member']
              });
      return Promise.resolve();
    },
    (error) => {

          throw new Error(error);
     
    }    
  );
  
};

export const getAtelierById = (id) => (dispatch) => {

    return Axios.get(ATELIERS_API+ "/" + id,{ headers: authHeader() })
  .then(
    (data) => {
     
      dispatch({
        type: FETCH_CURRENT_ATELIER,
        currentAtelier: data.data
      });
  
      return Promise.resolve();
    },
  
    (error) => {
        throw new Error(error)       
    }    
  );
  
};

export const addAtelier = (atelier, evenement) => (dispatch) => {
    
      atelier.evenement= "/api/evenements/" + evenement.id;
      if(atelier.dateDebut){
        atelier.dateDebut= atelier.dateDebut.toLocaleString("en-US", {timeZone: "Africa/Algiers"});
      }
      if(atelier.dateFin){
        atelier.dateFin= atelier.dateFin.toLocaleString("en-US", {timeZone: "Africa/Algiers"});
      }
    
      return Axios.post(ATELIERS_API, atelier, {headers: authHeader()}).then(
        (data) => {
    
          let atelier= data.data;
  
          dispatch({
            type: ADD_ATELIER_SUCCESS,
            currentAtelier: atelier
                  });
          
          dispatch({
            type: ADD_ATELIER_FAIL,
            addAtelierErrors: null
                  });
          return Promise.resolve();
        },
        (error) => {
    
          const message =error.response.data;
          const {violations} = message;
          const errorDescription = message["hydra:description"];
          if(violations){
    
            dispatch({
              type: ADD_ATELIER_FAIL,
              addAtelierErrors: violations
                    });
                    throw new Error(error);
          } else if(errorDescription){
            dispatch({
              type: ADD_ATELIER_FAIL_DESC,
              addAtelierErrorsDesc: errorDescription
                    });
                    throw new Error(error);
          } else{
            throw new Error(error)
          }
         
        }    
      );
    
};

export const updateAtelier = (id, atelier, evenement) => (dispatch) => {
  atelier.evenement= "/api/evenements/" + evenement.id;
  if(atelier.dateDebut){
    atelier.dateDebut= new Date(atelier.dateDebut).toLocaleString("en-US", {timeZone: "Africa/Algiers"});
  }
  if(atelier.dateFin){
    atelier.dateFin= new Date(atelier.dateFin).toLocaleString("en-US", {timeZone: "Africa/Algiers"});
  }

  if(atelier.inscriptions && atelier.inscriptions.length > 0) {
    atelier.inscriptions.forEach((inscription, index) => {
      atelier.inscriptions[index] = inscription['@id'];
    });
  }
  
    return Axios.put(ATELIERS_API + "/" + id, atelier, {headers: authHeader()}).then(
      (data) => {
  
        let atelier= data.data;
  
        dispatch({
          type: UPDATE_ATELIER_SUCCESS,
          currentAtelier: atelier
                });
        
        dispatch({
          type: UPDATE_ATELIER_FAIL,
          updateAtelierErrors: null
                });
        return Promise.resolve();
      },
      (error) => {
  
        const message =error.response.data;
        const {violations} = message;
        const errorDescription = message["hydra:description"];
        if(violations){
  
          dispatch({
            type: UPDATE_ATELIER_FAIL,
            updateAtelierErrors: violations
                  });
                  throw new Error(error);
        } else if(errorDescription){
          dispatch({
            type: UPDATE_ATELIER_FAIL_DESC,
            updateAtelierErrorsDesc: errorDescription
                  });
                  throw new Error(error);
        } else{
          throw new Error(error)
        }
       
      }    
    );
  
  };

  export const deleteAtelier = (id) => (dispatch) => {
    
    return Axios.delete(ATELIERS_API+"/" + id, {headers: authHeader()}).then(
      (data) => {
  
        dispatch({
          type: DELETE_ATELIER,
          id
        });
        
        return Promise.resolve();
      },
      (error) => {
          throw new Error(error)
      }    
    );
  
  };

  export const clearMsgAddAtelier = () => (dispatch) => {

    dispatch({
      type: ADD_ATELIER_FAIL,
      addAtelierErrors: null
            });
  
    dispatch({
      type: ADD_ATELIER_FAIL_DESC,
      addAtelierErrorsDesc: null
            });
  
    dispatch({
      type: UPDATE_ATELIER_FAIL,
      updateAtelierErrors: null
            });
        
    dispatch({
      type: UPDATE_ATELIER_FAIL_DESC,
      updateAtelierErrorsDesc: null
            });
  
    return Promise.resolve();
  
  };

  