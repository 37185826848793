// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getEventById } from '../../../store/actions/EvenementActions';
import { addInviteByAdmin, updateInvite, clearMsgAddInvite, getInviteById } from '../../../store/actions/InviteActions';
import { updateCheckInscription } from '../../../store/actions/InscriptionActions';
import { getAteliers } from '../../../store/actions/AtelierActions';
import Field from '../../components/Forms/Field';
import Select from "../../components/Forms/Select";
import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json"; 
import swal from 'sweetalert';
import { printPdf, printPdfBrowser, downloadPdf } from '../../../store/actions/PdfActions';
import { updateStatusInscription, sendBadgeEmail } from '../../../store/actions/InscriptionActions';
import { Step, Stepper } from 'react-form-stepper';
import Checkbox from '../Forms/Checkbox';
import { formatDateTimeAtelier } from '../../../helpers/FormatHelpers';

const Invite = ({getEventById, 
    addInviteByAdmin, 
    updateInvite,
    updateCheckInscription,
    currentEvenement, 
    addInviteErrors, 
    addInviteErrorDesc, 
    clearMsgAddInvite, 
    userId,
    getInviteById,
    currentInvite,
    printPdf,
    printPdfBrowser,
    downloadPdf,
    currentInscription,
    getAteliers,
    ateliers,
    updateStatusInscription,
    sendBadgeEmail
    }) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [loadingAtelier, setLoadingAtelier]= useState(true);
    const [isPrintable, setIsprintable]= useState(false);
    const [isVerifiedRecaptcha, setIsVerifiedRecaptcha]= useState(false);
    const recaptchaRef = React.createRef();
    const [isSubmited, setIsSubmited]= useState(false);
    const [goSteps, setGoSteps] = useState(0);
    const [listeAteliers, setListeAtelier] = useState();
    const [statusAteliers, setStatusAteliers] = useState({});
    const [isSubmitedAtelier, setIsSubmitedAtelier]= useState(false);
    const [allowAddAteliers, setAllowAddAteliers]= useState(false);

    const [credentials, setCredentials]= useState({
        nom: "",
        prenom: "",
        email: "",
        emailConfirm: "",
        tel: "",
        adresse: "",
        wilaya: "",
        titre: "Dr",
        specialite: "",
        checked: "false",
        paid: "false",
        reduction: "0"
      })

    const [errors, setErrors]= useState({
        nom: "",
        prenom: "",
        email: "",
        emailConfirm: "",
        tel: "",
        adresse: "",
        wilaya: "",
        titre: "",
        specialite: "",
        checked: "",
        paid: "",
        reduction: ""
    });

    const optionsWilaya = WilayaOfAlgeria;
    const params = useParams();
    let {id}= params;
    let {idVisiteur= "new"}= params;

    //Get types au chargement
    useEffect( () => {
        if(idVisiteur != "new"){
            setEditing(true);
            getInviteById(idVisiteur);
            setIsprintable(true);
        }else{
            setEditing(false);
        }
    }, [idVisiteur] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(currentInvite && idVisiteur != "new"){
                setLoading(true);
                setCredentials(currentInvite);
    
                const email = currentInvite.email ? currentInvite.email : "";
                const emailConfirm = currentInvite.email ? currentInvite.email : "";

                const isChecked = currentInvite.inscriptions && currentInvite.inscriptions.length > 0 && currentInvite.inscriptions[0].checked;
                const checkedValue = isChecked ? "true" : "false";

                const isPaid = currentInvite.inscriptions && currentInvite.inscriptions.length > 0 && currentInvite.inscriptions[0].paid;
                const paidValue = isPaid ? "true" : "false";

                if(!currentInvite.reduction){
                    currentInvite.reduction = "0";
                }

                setCredentials({...currentInvite, email, emailConfirm, checked: checkedValue, paid: paidValue});
            }
            setLoading(false);
        }

        fetchData();
        
    }, [currentInvite] )

    useEffect( () => {
        if(addInviteErrors){
               const apiErrors={};
               const violations=addInviteErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addInviteErrors])

    useEffect( () => {
        if(addInviteErrorDesc){
            swal("Impossible de vous inscrire", addInviteErrorDesc, "error")
        }
      
      }, [addInviteErrorDesc])
 
    useEffect( () => {  

        if(currentEvenement){
               setEvent(currentEvenement); 
        } 

     }, [currentEvenement])

    useEffect( () => {  

        if(currentInscription && isSubmited){
               setAllowAddAteliers(true);
        } 

     }, [currentInscription])

    useEffect( () => {  

        const fetchDataAtelier = async () => 
        {
            if(ateliers && ateliers.length>0){
                setLoadingAtelier(true);

                ateliers.sort((a, b) => {
                    if (a.nom < b.nom) {
                        return -1;
                    }
                    if (a.nom > b.nom) {
                        return 1;
                    }
                    return 0;
                });

                setListeAtelier(ateliers); 
 
                if(!editing){
                 const initialStatus = ateliers.reduce((acc, atelier) => {
                     acc[atelier.id] = false;
                     return acc;
                 }, {});
                 setStatusAteliers(initialStatus);
                } else if (currentInvite && currentInvite.inscriptions && currentInvite.inscriptions.length > 0) {
                 
                 const initialStatus = ateliers.reduce((acc, atelier) => {
                     // Vérifier si l'atelier est présent dans les ateliers de l'inscription
                     const isPresent = currentInvite.inscriptions[0].ateliers.some(inscriptionAtelier => inscriptionAtelier.id === atelier.id);
                     // Mettre à jour le statut en conséquence
                     acc[atelier.id] = isPresent;
                     return acc;
                 }, {});
     
                 setStatusAteliers(initialStatus);
 
             }
         } 
 
         setLoadingAtelier(false);
        }

        fetchDataAtelier();

     }, [ateliers, currentInvite])

    //Récupération d'un event
    const fetchEvent= async id  => {
        try {
               await getEventById(id);
               if(!editing){
                setLoading(false);
                setLoadingAtelier(false);
               }
        } catch (error) {
               toast.error("Impossible de charger l'évenement");  
               Navigate("/");
        }
    }

    //Récupération d'un event
    const fetchAteliers= async id  => {
        try {
               await getAteliers(id);
        } catch (error) {
               toast.error("Impossible de charger les ateliers");  
               Navigate("/");
        }
    }

    // Chargement de l'event au chargement du composent ou changement de l'id  
    useEffect( () => {
        setLoading(true);
        setLoadingAtelier(true);
        fetchEvent(id);
        fetchAteliers(id);
    }, [id])

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddInvite();

        if(credentials.email !== credentials.emailConfirm){
                toast.error("Une erreur est survenue !");
                setErrors({errors, emailConfirm: "Les deux adresses email doivent être identiques"});
        }else{

            if(editing){

                try {

                    if(currentInvite.inscriptions && 
                        currentInvite.inscriptions.length > 0 && 
                        (
                            !currentInvite.inscriptions[0].checked ||  (currentInvite.inscriptions[0].checked && currentInvite.inscriptions[0].checked.toString() !== credentials.checked) ||
                            !currentInvite.inscriptions[0].paid || (currentInvite.inscriptions[0].paid && currentInvite.inscriptions[0].paid.toString() !== credentials.paid)
                        )
                        ){
                            const checkedValue = credentials.checked == "true" ? true: false;
                            const paidValue = credentials.paid == "true" ? true: false;
                            
                            await updateCheckInscription(
                                currentInvite.id,
                                currentInvite.inscriptions[0].id,
                                checkedValue,
                                paidValue
                            );
                    }

                    await updateInvite(idVisiteur, credentials, event);
                    //if response
                    setErrors({});
                    swal("L'inscription est à jour", "success");
                    setGoSteps(1);
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {
                    setIsSubmited(true);
                    await addInviteByAdmin(credentials, event, userId);
                    //if response
                    setErrors({});
                    swal("L'inscription est bien enregistrée", "success");
                    setIsprintable(true);
                    setGoSteps(1);
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

        }

        
            
    }

    const onSetAteliers = async (e) =>{
        e.preventDefault();

                    // Récupérer les ID des ateliers sélectionnés
                        const selectedAtelierIds = Object.keys(statusAteliers)
                        .filter(id => statusAteliers[id] === true);

                    // Construire la liste des ateliers pour l'inscription
                    const ateliersForInscription = selectedAtelierIds.map(id => `/api/ateliers/${id}`);

                    let updatedInscription;

                    {editing ? 
                        updatedInscription = {
                            ...currentInvite.inscriptions[0],
                            evenement: "/api/evenements/" + currentInvite.inscriptions[0].evenement.id,
                            ateliers: ateliersForInscription
                        }
                    :
                        updatedInscription = {
                            ...currentInscription,
                            invite: "/api/invites/" + currentInvite.id,
                            ateliers: ateliersForInscription
                        }
                    }

                    // Mettre à jour l'invite avec les nouveaux ateliers
                    try {
                        if(editing){
                            await updateStatusInscription(currentInvite.inscriptions[0].id, updatedInscription);
                        } else {
                            await updateStatusInscription(currentInscription.id, updatedInscription);

                        }
                        setIsSubmitedAtelier(true);
                        swal("L'inscription aux ateliers est à jour", "success");
                    } catch (err) {
                        toast.error("Une erreur est survenue !");
                    }

    };

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    const handleChangeAtelierStatus = (id) => {
        setStatusAteliers({
            ...statusAteliers,
            [id]: !statusAteliers[id] // Inverser l'état actuel de l'atelier
        });
    };

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddInvite();
               setEditing(false);
        }
    }, [])

    const onChangeRecaptcha= (value) => {
        setIsVerifiedRecaptcha(true);
    }

    const handlePrint = async () => {
        try {
            if(editing){
                await printPdf(currentInvite.inscriptions[0].id, currentInvite.evenement.id, currentInvite.id);
            }else{
                await printPdf(currentInscription.id, currentInvite.evenement.id, currentInvite.id);
            }
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handlePrintBrowser = async (idInscription, idEvenement, idInvite) => {
        try {
            if(editing){
                await printPdfBrowser(currentInvite.inscriptions[0].id, currentInvite.evenement.id, currentInvite.id);
            }else{
                await printPdfBrowser(currentInscription.id, currentInvite.evenement.id, currentInvite.id);
            }
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleDownload = async (idInscription, idEvenement, idInvite) => {
        try {
            if(editing){
                await downloadPdf(currentInvite.inscriptions[0].id, currentInvite.evenement.id, currentInvite.id);
            }else{
                await downloadPdf(currentInscription.id, currentInvite.evenement.id, currentInvite.id);
            }
        } catch(err){
            toast.error("Erreur lors du chargement du pdf");
        }
    };

    const handleSend = async () => {

        if(currentInvite && currentInvite.email && currentInvite.email !=""){
            try {
                if(editing){
                    await sendBadgeEmail(currentInvite.inscriptions[0].id);
                }else{
                    await sendBadgeEmail(currentInscription.id);
                }
                toast.success("Badge envoyé par email à l'adresse " + currentInvite.email);
            } catch(err){
                toast.error("Erreur lors de l'envoi de l'émail");
            }
        } else {
            toast.error("Veuillez renseigner l'adresse e-mail de l'invité");
        }
        
    };

    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/visiteurs"}> Visiteurs</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification | "
                            : "Ajout du visiteur"}

                            {editing && !loading && currentInvite &&
                            currentInvite.prenom + " " + currentInvite.nom}

                             </h4>

                                {isPrintable &&
                                <div className="printBtn">
                                    <Link className="btn shadow btn-md sharp me-1 btn-success"
                                    onClick={() => handlePrint()}>
                                        <i class="fas fa-print fa-lg"></i>
                                    </Link>
                                    <Link className="btn btn-primary shadow btn-md sharp me-1" 
                                    onClick={() => handlePrintBrowser()}>
                                        <i class="fas fa-file-pdf fa-lg"></i>
                                    </Link>

                                    <Link className="btn btn-info shadow btn-md sharp me-1" 
                                    onClick={() => handleDownload()}>
                                        <i class="fas fa-file-download fa-lg"></i>
                                    </Link>

                                    <Link className="btn btn-success shadow btn-md sharp me-1" 
                                    onClick={() => handleSend()}>
                                        <i class="fas fa-envelope fa-lg"></i>
                                    </Link>
                                </div>
                                }

                                <div>

                                    {!editing && isSubmited &&
                                    <Link className="btn btn-secondary" style={{ marginRight: "10px"}} onClick={() => reloadPage()}>
                                        <i class="fas fa-spinner"  style={{ marginRight: "5px"}}></i>  
                                        Actualiser
                                    </Link>
                                    }

                                    <Link to={"/" + id + "/admin/visiteurs"} className="btn btn-primary">
                                        <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                        Retour à la liste
                                    </Link>
                                </div>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                            <div className="form-wizard ">
								<Stepper className="nav-wizard stepperPerso" activeStep={goSteps} label={false}>
									<Step className="nav-link" onClick={() => setGoSteps(0)}>1</Step>
									<Link onClick={() => (editing || isSubmited) && setGoSteps(1)}><Step className="nav-link" >2</Step></Link>
								</Stepper>
                                </div>

                                <div className="PersoEvenetFragment eventPageFragment">
                                    <Container>
                                    {!loading &&
                                        
                                        goSteps === 0 && (

                                        <Row>
                                                

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>
                                                {isSubmited ?
                                                <div className="text-end toolbar toolbar-bottom p-2">
                                                    <button  className="btn btn-success sw-btn-next" onClick={() => setGoSteps(1)}>Suivant</button>
                                                </div>
                                                :
                                                null}
                                                	
                                                
                                                <Col md="6">
                                                    <Field
                                                        name="prenom"
                                                        value={credentials.prenom}
                                                        onChange={handleChangeFields}
                                                        label= "Prénom *"
                                                        placeholder="Prénom"
                                                        error={errors.prenom}
                                                        type="text" 
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Nom *"
                                                        placeholder="Nom"
                                                        error={errors.nom}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="email"
                                                        value={credentials.email}
                                                        onChange={handleChangeFields}
                                                        label= "Email"
                                                        placeholder="Email"
                                                        error={errors.email}
                                                        type="email" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="emailConfirm"
                                                        value={credentials.emailConfirm}
                                                        onChange={handleChangeFields}
                                                        label= "Confirmez l'email"
                                                        placeholder="Confirmez l'email"
                                                        error={errors.emailConfirm}
                                                        type="email" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="tel"
                                                        value={credentials.tel}
                                                        onChange={handleChangeFields}
                                                        label= "Téléphone"
                                                        placeholder="Téléphone"
                                                        error={errors.tel}
                                                        type="tel"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="adresse"
                                                        value={credentials.adresse}
                                                        onChange={handleChangeFields}
                                                        label= "Adresse professionnelle"
                                                        placeholder="Adresse professionnelle"
                                                        error={errors.adresse}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Select name="wilaya"
                                                    value={credentials.wilaya}
                                                    label="Wilaya *"
                                                    onChange={handleChangeFields}
                                                    required="true"
                                                    >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une wilaya
                                                    </option> 

                                                    {optionsWilaya.map(wilaya  =>  (
                                                    <option key={wilaya.id} value={wilaya.name}>
                                                        {wilaya.name + " - " + wilaya.code + " - " + wilaya.ar_name}
                                                        </option> 
                                                        ))}
                              
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="specialite"
                                                        value={credentials.specialite}
                                                        label="Spécialité"
                                                        onChange={handleChangeFields}
                                                        >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une spécialité
                                                    </option> 

                                                    {listeSpecialites
                                                    .sort(function(a, b) {
                                                        if(a.toLowerCase() < b.toLowerCase()) return -1;
                                                        if(a.toLowerCase() > b.toLowerCase()) return 1;
                                                        return 0;
                                                        })
                                                    .map(specialite  =>  (
                                                    <option key={specialite} value={specialite}>
                                                        {specialite}
                                                        </option> 
                                                        ))}

                                                    <option value="autre">
                                                        Autre
                                                    </option> 
                                
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="titre"
                                                        value={credentials.titre}
                                                        label="Titre professionnel"
                                                        onChange={handleChangeFields}
                                                        >

                                                    <option key="Dr" value="Dr" defaultChecked>
                                                        Dr
                                                    </option>

                                                    <option key="Pr" value="Pr">
                                                        Pr
                                                    </option>

                                                    <option value="">
                                                        Autre
                                                    </option>

                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="checked"
                                                        value={credentials.checked}
                                                        label="Validé à l'entrée"
                                                        onChange={handleChangeFields}
                                                        required="true"
                                                        >

                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une option
                                                    </option> 

                                                    <option value="true" defaultChecked>
                                                        Oui
                                                    </option> 

                                                    <option value="false">
                                                        Non
                                                    </option> 
                                
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="paid"
                                                        value={credentials.paid}
                                                        label="Payé"
                                                        onChange={handleChangeFields}
                                                        >

                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une option
                                                    </option> 

                                                    <option value="true">
                                                        Oui
                                                    </option> 

                                                    <option value="false">
                                                        Non
                                                    </option> 
                                
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="reduction"
                                                        value={credentials.reduction}
                                                        onChange={handleChangeFields}
                                                        label= "Réduction (DA)"
                                                        placeholder="Réduction"
                                                        error={errors.reduction}
                                                        type="numeric"
                                                        required="false" 
                                                        />
                                                </Col>

                                                

                                                </Row>
                                                
                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                        disabled= {!editing && isSubmited}
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>
                                        
                                        )}
                                        
                                        {goSteps === 1 && !loadingAtelier && (
                                            <>
                                                <div className="text-end toolbar toolbar-bottom p-2">
                                                    

                                                    {listeAteliers && listeAteliers.length > 0 ?
                                                        <form onSubmit={onSetAteliers}>
                                                            <h3 className="text-center mb-4">Veuillez sélectionner les ateliers</h3>
                                                            <Row>
                                                            {listeAteliers.map((atelier, index)  =>  (
                                                            <Col md="3" className="text-center mt-2 ateliersCheckbox">
                                                                <Checkbox
                                                                name="atelier"
                                                                value={atelier.nom}
                                                                onChange={() => handleChangeAtelierStatus(atelier.id)}
                                                                required="true" 
                                                                checked={statusAteliers[atelier.id]}
                                                                />
                                                                {atelier.date_debut && atelier.date_fin ? 
                                                                    formatDateTimeAtelier(atelier.date_debut, atelier.date_fin)
                                                                :
                                                                null}
                                                            </Col>
                                                            )
                                                            )}
                                                            <div className="text-center mt-4">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary btn-lg"
                                                                    disabled= {(!editing && isSubmitedAtelier) || (!editing && !allowAddAteliers)}
                                                                >
                                                                    Enregistrer les ateliers
                                                                </button>
                                                            </div>
                                                            <div className="text-center mt-2">
                                                                <Link
                                                                    className="btn btn-info btn-md"
                                                                    to={"/" + id + "/admin/visiteurs"}
                                                                >
                                                                    {!editing && !isSubmitedAtelier ?
                                                                    "Ignorer"
                                                                    :
                                                                    "Retour à la liste"
                                                                    }
                                                                </Link>
                                                            </div>
                                                            </Row>
                                                        </form>
                                                        :
                                                        <>
                                                        <h4 className='text-center'>Il n'existe actuellement aucun atelier</h4>
                                                        <div className="text-center mt-2">
                                                            <Link
                                                                        className="btn btn-info btn-md"
                                                                        to={"/" + id + "/admin/visiteurs"}
                                                                    >
                                                                        Retour à la liste
                                                            </Link>
                                                        </div>
                                                        </>
                                                        }

                                                </div>	
                                            </>
                                        )}


                                    </Container>
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        currentEvenement: state.evenementState.currentEvenement,
        addInviteErrors: state.inviteState.addInviteErrors,
        addInviteErrorDesc: state.inviteState.addInviteErrorDesc,
        userId: state.auth.user.id,
        currentInvite: state.inviteState.currentInvite,
        currentInscription: state.inscriptionState.currentInscription,
        ateliers: state.atelierState.ateliers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        addInviteByAdmin, 
        updateInvite, 
        updateCheckInscription,
        clearMsgAddInvite, 
        getInviteById,
        printPdf,
        printPdfBrowser,
        downloadPdf,
        getAteliers,
        updateStatusInscription,
        sendBadgeEmail },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Invite); 