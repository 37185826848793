// @flow 
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link, Navigate, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getEventById } from '../../../store/actions/EvenementActions';
import { addInvite, clearMsgAddInvite } from '../../../store/actions/InviteActions';
import { getAteliers } from '../../../store/actions/AtelierActions';
import { updateStatusInscriptionByInvite } from '../../../store/actions/InscriptionActions';
import logo from "../../../images/logo-forum.png";
import Field from '../../components/Forms/Field';
import Select from "../../components/Forms/Select";
import WilayaOfAlgeria from "../../../helpers/Wilaya_Of_Algeria.json";
import listeSpecialites from "../../../helpers/Liste_specialites.json"; 
import listeTitres from "../../../helpers/Titre_medecin.json";  
import HeaderNoAuth from '../../components/HeaderNoAuth';
import RecaptchaSection from '../../components/RecaptchaSection';
import swal from 'sweetalert';
import FooterPublic from '../../layouts/FooterPublic';
import { CHECKOUT_STATUS_API } from '../../../services/config';
import axios from 'axios';
import { Step, Stepper } from 'react-form-stepper';
import Checkbox from '../../components/Forms/Checkbox';
import { formatDateTimeAtelier } from '../../../helpers/FormatHelpers';

const Inscription = ({getEventById, 
    addInvite, 
    currentEvenement, 
    addInviteErrors, 
    addInviteErrorDesc, 
    clearMsgAddInvite,
    getAteliers,
    ateliers,
    updateStatusInscriptionByInvite,
    currentInscription}) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);
    const [isVerifiedRecaptcha, setIsVerifiedRecaptcha]= useState(false);
    const recaptchaRef = React.createRef();
    const [isChargilyEnabled, setIsChargilyEnabled]= useState(false);
    const [goSteps, setGoSteps] = useState(0);
    const [listeAteliers, setListeAtelier] = useState();
    const [statusAteliers, setStatusAteliers] = useState({});
    const [isSubmitedAtelier, setIsSubmitedAtelier]= useState(false);
    const [isSubmited, setIsSubmited]= useState(false);
    const [allowAddAteliers, setAllowAddAteliers]= useState(false);

    const [credentials, setCredentials]= useState({
        nom: "",
        prenom: "",
        email: "",
        emailConfirm: "",
        tel: "",
        adresse: "",
        wilaya: "",
        titre: "Dr",
        specialite: ""
      })

    const [errors, setErrors]= useState({
        nom: "",
        prenom: "",
        email: "",
        emailConfirm: "",
        tel: "",
        adresse: "",
        wilaya: "",
        titre: "",
        specialite: ""
    });

    const optionsWilaya = WilayaOfAlgeria;
    const params = useParams();
    let {id}= params;
	const navigate = useNavigate();

    useEffect( () => {
        if(addInviteErrors){
               const apiErrors={};
               const violations=addInviteErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addInviteErrors])

    useEffect( () => {
        if(addInviteErrorDesc){
            swal("Impossible de vous inscrire", addInviteErrorDesc, "error")
        }
      
      }, [addInviteErrorDesc])
 
    useEffect( () => {  

        if(currentEvenement){
               setEvent(currentEvenement); 

               axios.get(CHECKOUT_STATUS_API)
                    .then(response => {
                        const charilyEnabled = response.data;
                        if(charilyEnabled && charilyEnabled == "true"){
                            setIsChargilyEnabled(true);
                        }
                    })
                    .catch();
        } 

     }, [currentEvenement])

    //Récupération d'un event
    const fetchEvent= async id  => {
        try {
               await getEventById(id);
               setLoading(false);  
        } catch (error) {
               toast.error("Impossible de charger l'évenement");  
               Navigate("/");
        }
    }

    //Récupération d'un event
    const fetchAteliers= async id  => {
        try {
               await getAteliers(id);
        } catch (error) {
               toast.error("Impossible de charger les ateliers");  
               Navigate("/");
        }
    }

    // Chargement de l'event au chargement du composent ou changement de l'id  
    useEffect( () => {
        setLoading(true);
        setEditing(true);
        if((currentEvenement && currentEvenement.id == id) || !id ){
          setLoading(false);
        }else{
          fetchEvent(id);
        }
        fetchAteliers(id);
        
    }, [id])

    useEffect( () => {  

        if(ateliers && ateliers.length>0){

                ateliers.sort((a, b) => {
                    if (a.nom < b.nom) {
                        return -1;
                    }
                    if (a.nom > b.nom) {
                        return 1;
                    }
                    return 0;
                });

               setListeAtelier(ateliers); 

               const initialStatus = ateliers.reduce((acc, atelier) => {
                    acc[atelier.id] = false;
                    return acc;
                }, {});
                setStatusAteliers(initialStatus);
        } 

     }, [ateliers])

     useEffect( () => {  

        if(currentInscription && isSubmited){
               setAllowAddAteliers(true);
        } 

     }, [currentInscription])

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddInvite();

        if(credentials.email !== credentials.emailConfirm){
                toast.error("Une erreur est survenue !");
                setErrors({errors, emailConfirm: "Les deux adresses email doivent être identiques"});
        }else{
            try {
                await addInvite(credentials, event);
                
                //if response
                setErrors({});
                setIsSubmited(true);
                if(listeAteliers && listeAteliers.length >0){
                    setGoSteps(1);
                    window.scrollTo(0, 0);
                } else if(isChargilyEnabled){
                    swal("Votre inscription est bien enregistrée", "Veuillez vérifier votre boîte de réception email (pensez à consulter votre dossier de spam)", "success").then(() => {
                        if(isChargilyEnabled){
                            window.location.href = `/${id}/checkout/${credentials.email}`;
                        }
                    });
                } else {
                    swal("Votre inscription est bien enregistrée", "Veuillez vérifier votre boîte de réception email (pensez à consulter votre dossier de spam)", "success").then(() => {
                            window.location.pathname= ("");
                    });
                }
            }   catch (err) {
                toast.error("Une erreur est survenue !");

            }
        }

        
            
    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddInvite();
        }
    }, [])

    const onChangeRecaptcha= (value) => {
        setIsVerifiedRecaptcha(true);
    }

    const handleChangeAtelierStatus = (id) => {
        setStatusAteliers({
            ...statusAteliers,
            [id]: !statusAteliers[id] // Inverser l'état actuel de l'atelier
        });
    };

    const onSetAteliers = async (e) =>{
        e.preventDefault();

                    // Récupérer les ID des ateliers sélectionnés
                    const selectedAtelierIds = Object.keys(statusAteliers)
                    .filter(id => statusAteliers[id] === true);

                    // Construire la liste des ateliers pour l'inscription
                    const ateliersForInscription = selectedAtelierIds.map(id => id);

                    // Mettre à jour l'invite avec les nouveaux ateliers
                    try {
                        await updateStatusInscriptionByInvite(currentInscription.id, ateliersForInscription);
                        setIsSubmitedAtelier(true);
                        swal("Votre inscription est bien enregistrée", "Veuillez vérifier votre boîte de réception email (pensez à consulter votre dossier de spam)", "success").then(() => {
                            if(isChargilyEnabled){
                                window.location.href = `/${id}/checkout/${credentials.email}`;
                            } else {
                                window.location.pathname= "";
                            }
                        });
                    } catch (err) {
                        toast.error("Une erreur est survenue !");
                    }

    };

    const ignoreAtelier= () => {
        setIsSubmitedAtelier(true);
        swal("Votre inscription est bien enregistrée", "Veuillez vérifier votre boîte de réception email (pensez à consulter votre dossier de spam)", "success").then(() => {
            if(isChargilyEnabled){
                window.location.href = `/${id}/checkout/${credentials.email}`;
            } else {
                window.location.pathname= "";
            }
        });
    }
    
    return (

        <>
            <HeaderNoAuth />

        <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                {listeAteliers && listeAteliers.length >0 ?
                                <div className="form-wizard ">
                                    <Stepper className="nav-wizard stepperPerso" activeStep={goSteps} label={false}>
                                        <Step className="nav-link">1</Step>
                                        <Step className="nav-link" >2</Step>
                                    </Stepper>
                                </div>
                                :
                                null
                                }

                                <div className="card-header">
                                    {!loading ?
                                        <h2 className="card-title" style={{textAlign: "center"}}>
                                            Inscription visiteur - {currentEvenement.nom} 
                                        <br></br> 
                                        {currentEvenement.tarif &&  "Tarif d'accès: " + currentEvenement.tarif + " DA"}
                                        </h2>:
                                        <h2 className="card-title">Inscription visiteur </h2>
                                    }
                                </div>

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        {goSteps === 0 && (
                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="prenom"
                                                        value={credentials.prenom}
                                                        onChange={handleChangeFields}
                                                        label= "Prénom *"
                                                        placeholder="Prénom"
                                                        error={errors.prenom}
                                                        type="text" 
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Nom *"
                                                        placeholder="Nom"
                                                        error={errors.nom}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="email"
                                                        value={credentials.email}
                                                        onChange={handleChangeFields}
                                                        label= "Email *"
                                                        placeholder="Email"
                                                        error={errors.email}
                                                        type="email" 
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="emailConfirm"
                                                        value={credentials.emailConfirm}
                                                        onChange={handleChangeFields}
                                                        label= "Confirmez votre email *"
                                                        placeholder="Confirmez votre email"
                                                        error={errors.emailConfirm}
                                                        type="email" 
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="tel"
                                                        value={credentials.tel}
                                                        onChange={handleChangeFields}
                                                        label= "Téléphone *"
                                                        placeholder="Téléphone"
                                                        error={errors.tel}
                                                        type="tel"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="adresse"
                                                        value={credentials.adresse}
                                                        onChange={handleChangeFields}
                                                        label= "Adresse professionnelle *"
                                                        placeholder="Adresse professionnelle"
                                                        error={errors.adresse}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Select name="wilaya"
                                                    value={credentials.wilaya}
                                                    label="Wilaya *"
                                                    onChange={handleChangeFields}
                                                    required="true"
                                                    >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une wilaya
                                                    </option> 

                                                    {optionsWilaya.map(wilaya  =>  (
                                                    <option key={wilaya.id} value={wilaya.name}>
                                                        {wilaya.name + " - " + wilaya.code + " - " + wilaya.ar_name}
                                                        </option> 
                                                        ))}
                              
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="specialite"
                                                        value={credentials.specialite}
                                                        label="Spécialité"
                                                        onChange={handleChangeFields}
                                                        >
                                                    <option value="" defaultChecked>
                                                        Veuillez sélectionner une spécialité
                                                    </option> 

                                                    {listeSpecialites
                                                    .sort(function(a, b) {
                                                        if(a.toLowerCase() < b.toLowerCase()) return -1;
                                                        if(a.toLowerCase() > b.toLowerCase()) return 1;
                                                        return 0;
                                                        })
                                                    .map(specialite  =>  (
                                                    <option key={specialite} value={specialite}>
                                                        {specialite}
                                                        </option> 
                                                        ))}

                                                    <option value="autre">
                                                        Autre
                                                    </option> 
                                
                                                    </Select>
                                                </Col>

                                                <Col md="6">
                                                    <Select name="titre"
                                                        value={credentials.titre}
                                                        label="Titre professionnel"
                                                        onChange={handleChangeFields}
                                                        >

                                                    <option key="Dr" value="Dr" defaultChecked>
                                                        Dr
                                                    </option>

                                                    <option key="Pr" value="Pr">
                                                        Pr
                                                    </option>

                                                    <option value="">
                                                        Autre
                                                    </option>

                                                    </Select>
                                                </Col>


                                                </Row>

                                                {/* <RecaptchaSection onChangeRecaptcha={onChangeRecaptcha} recaptchaRef={recaptchaRef}/> */}

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                        //disabled= {!isVerifiedRecaptcha}
                                                    >
                                                        S'inscrire
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>
                                        )}

                                        {goSteps === 1 && (
                                            <>
                                                <div className="text-end toolbar toolbar-bottom p-2">

                                                    {listeAteliers && listeAteliers.length > 0 ?
                                                        <form onSubmit={onSetAteliers}>
                                                            <h3 className="text-center mb-4">Veuillez sélectionner les ateliers</h3>
                                                            <div style= {{display: "inline"}}> <h4 className="text-center importantNote"> IMPORTANT</h4> <h4 className="text-center mb-4 importantNote">En raison du nombre de places limité, veuillez noter que l'inscription devra également être effectuée sur place aux stands du Forum et de BEM. Nous vous remercions de votre compréhension. </h4> </div>
                                                            
                                                            <Row>
                                                            {listeAteliers.map((atelier, index)  =>  (
                                                            <Col md="3" className="text-center mt-2 ateliersCheckbox">
                                                                <Checkbox
                                                                name="atelier"
                                                                value={atelier.nom}
                                                                onChange={() => handleChangeAtelierStatus(atelier.id)}
                                                                required="true" 
                                                                checked={statusAteliers[atelier.id]}
                                                                />
                                                                {atelier.date_debut && atelier.date_fin ? 
                                                                    formatDateTimeAtelier(atelier.date_debut, atelier.date_fin)
                                                                :
                                                                null}
                                                            </Col>
                                                            )
                                                            )}
                                                            <div className="text-center mt-4">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary btn-lg"
                                                                    disabled= {(!editing && isSubmitedAtelier) || !allowAddAteliers}
                                                                >
                                                                    S'inscrire aux ateliers
                                                                </button>
                                                            </div>
                                                            <div className="text-center mt-2">
                                                                <Link
                                                                    className="btn btn-info btn-md"
                                                                    onClick={() => ignoreAtelier()}
                                                                >
                                                                    Ignorer
                                                                </Link>
                                                            </div>
                                                            </Row>
                                                        </form>
                                                        :
                                                        <>
                                                        <h4 className='text-center'>Il n'existe actuellement aucun atelier</h4>
                                                        <div className="text-center mt-2">
                                                            <Link
                                                                        className="btn btn-info btn-md"
                                                                        to={"/" + id + "/admin/visiteurs"}
                                                                    >
                                                                        Retour à la liste
                                                            </Link>
                                                        </div>
                                                        </>
                                                        }

                                                </div>	
                                            </>
                                        )}

                                    </Container>
                                    }
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

        <FooterPublic />
        </>

    );
};


const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        currentEvenement: state.evenementState.currentEvenement,
        addInviteErrors: state.inviteState.addInviteErrors,
        addInviteErrorDesc: state.inviteState.addInviteErrorDesc,
        currentInscription: state.inscriptionState.currentInscription,
        ateliers: state.atelierState.ateliers,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        addInvite, 
        clearMsgAddInvite,
        getAteliers,
        updateStatusInscriptionByInvite },
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Inscription); 