import React, { useContext } from "react";
import{useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/// React router dom
import {  Routes, Route, Outlet  } from "react-router-dom";

/// Css
//import "swiper/css";
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from './layouts/ScrollToTop';
/// Dashboard
import Home from './components/Dashboard/Home';
import EventList from './components/Dashboard/EventList';
import EventPage from './components/Dashboard/EventPage';
import Analytics from './components/Dashboard/Analytics';
import Reviews from './components/Dashboard/Reviews';

//Ticket
import CreateTicket from './components/Ticket/CreateTicket';
import AllTicket from './components/Ticket/AllTicket';

//Customers
import CustomersList from './components/Customers/CustomersList';

/// App
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";

/// Plugins
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Pages
import Login from "./pages/Login";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import Reports from "./pages/Reports";
import Invites from "./components/invites/Invites";
import Invite from "./components/invites/Invite";
import Organisateur from "./components/organisateurs/Organisateur";
import Organisateurs from "./components/organisateurs/Organisateurs";
import Orateurs from "./components/orateurs/Orateurs";
import Orateur from "./components/orateurs/Orateur";
import Sponsor from "./components/sponsors/Sponsor";
import Sponsors from "./components/sponsors/Sponsors";
import Presse from "./components/presses/Presse";
import Presses from "./components/presses/Presses";
import User from "./components/users/User";
import Users from "./components/users/Users";
import Event from "./components/evenements/Event";
import Events from "./components/evenements/Events";
import Partenaire from "./components/Partenaire/Partenaire";
import Organisme from "./components/Organisme/organisme";
import Atelier from "./components/Ateliers/Atelier";
import Ateliers from "./components/Ateliers/Ateliers";
import Attestation from "./components/Attestations/Attestation";
import Attestations from "./components/Attestations/Attestations";
import Adherent from "./components/Adherents/Adherent";
import Adherents from "./components/Adherents/Adherents";

const Markup = ({currentUser}) => {
  //const { menuToggle } = useContext(ThemeContext);
  const allroutes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: 'dashboard', component: <Home/> },
	  { url: 'event-list', component: <EventList/> },
	  { url: 'event', component: <EventPage/> },
	  { url: 'analytics', component: <Analytics/> },
	  { url: 'reviews', component: <Reviews/> },
    
    //Ticket
	  { url: 'create-ticket', component: <CreateTicket/> },
	  { url: 'all-ticket', component: <AllTicket/> },

    //Customers
    {url:'customers-list', component:<CustomersList/> },
    {url:'visiteurs/:idVisiteur', component:<Invite/> },
    {url:'visiteurs', component:<Invites/> },

    {url:'organisateurs/:idOrganisateur', component:<Organisateur/> },
    {url:'organisateurs', component:<Organisateurs/> },

    {url:'orateurs/:idOrateur', component:<Orateur/> },
    {url:'orateurs', component:<Orateurs/> },

    {url:'sponsors/:idSponsor', component:<Sponsor/> },
    {url:'sponsors', component:<Sponsors/> },

    {url:'presses/:idPresse', component:<Presse/> },
    {url:'presses', component:<Presses/> },

    {url:'events/:idEvent', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <Event/> :  <Error403 /> },
    {url:'events', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <Events/>  :  <Error403 /> },

    {url:'partenaire', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <Partenaire/>  :  <Error403 /> },

    {url:'organisme', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <Organisme/>  :  <Error403 /> },

    {url:'ateliers/:idAtelier', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <Atelier/> :  <Error403 /> },
    {url:'ateliers', component: <Ateliers/>},

    {url:'attestations/:idAttestation', component: <Attestation/>},
    {url:'attestations', component: <Attestations/> },

    {url:'users/:idUser', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <User/> :  <Error403 />},
    {url:'users', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <Users/>  :  <Error403 />},
 
    {url:'adherents/:idAdherent', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <Adherent/> :  <Error403 /> },
    {url:'adherents', component: currentUser && currentUser.roles[0]=="ROLE_ADMIN" ? <Adherents/>  :  <Error403 /> },

    //Reports
    {url:'reports', component:<Reports/> },


    /// Apps
    { url: "edit-profile", component: <EditProfile/> },

    /// Plugin
    { url: "uc-sweetalert", component: <MainSweetAlert/> },
	
    /// Widget
    { url: "widget-basic", component: <Widget/> },

    /// table
	{ url: 'table-filtering', component: <FilteringTable /> },
    { url: 'table-sorting', component: <SortingTable /> },
    { url: "table-datatable-basic", component: <DataTable /> },
    { url: "table-bootstrap-basic", component: <BootstrapTable /> },

    /// pages
    { url: "page-login", component: <Login /> },
    { url: "page-error-400", component: <Error400/> },
    { url: "page-error-403", component: <Error403/> },
    { url: "page-error-404", component: <Error404 /> },
    { url: "page-error-500", component: <Error500/> },
    { url: "page-error-503", component: <Error503/> },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <>
      <Routes>
          <Route path='page-error-400' element={<Error400/>} />
          <Route path='page-error-403' element={<Error403/>} />
          <Route path='page-error-404' element={<Error404/>} />
          <Route path='page-error-500' element={<Error500/>} />
          <Route path='page-error-503' element={<Error503/>} />
          <Route  element={<MainLayout />} > 
              {allroutes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`${data.url}`}
                  element={data.component}
                />
              ))}
          </Route>
      </Routes>
	  <ScrollToTop />
    </>
  );
};

function MainLayout(){
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ menuToggle ? "menu-toggle" : ""}`}>  
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <Outlet />                
          </div>
      </div>
      <Footer />
    </div>
  )

};

const mapStateToProps = (state) => {
  return {
      isAuthenticated: "",
      currentUser: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
      {  },
      dispatch,
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(Markup); 