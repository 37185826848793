// @flow 
import React,{useState, useEffect, useRef, useLayoutEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navigate, Link, useParams, useLocation } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { getAtelierById, addAtelier, updateAtelier, clearMsgAddAtelier } from '../../../store/actions/AtelierActions';
import { toast } from 'react-toastify';
import { getEventById } from '../../../store/actions/EvenementActions';
import Field from '../../components/Forms/Field';
import swal from 'sweetalert';
import { formatDateTimeJs } from '../../../helpers/FormatHelpers';

const Atelier = ({getEventById, 
    addAtelier, 
    updateAtelier, 
    clearMsgAddAtelier, 
    getAtelierById,
    currentEvenement, 
    userId,
    currentAtelier,
    addAtelierErrors,
    addAtelierErrorsDesc,
    updateAtelierErrors,
    updateAtelierErrorsDesc
    }) => {

    const [event, setEvent]= useState({});
    const [editing, setEditing]= useState(false); 
    const [loading, setLoading]= useState(true);

    const [credentials, setCredentials]= useState({
        nom: "",
        description: "",
        dateDebut: null,
        dateFin: null
      })

    const [errors, setErrors]= useState({
        nom: "",
        description: "",
        dateDebut: "",
        dateFin: ""
    });

    const params = useParams();
    let {id}= params;
    let {idAtelier= "new"}= params;

    //Get types au chargement
    useEffect( () => {
        if(idAtelier != "new"){
            setEditing(true);
            getAtelierById(idAtelier);
        }else{
            setEditing(false);
        }
    }, [idAtelier] )

    useEffect( () => {

        const fetchData = async () => 
        {
            if(currentAtelier && idAtelier != "new"){
                setLoading(true);
                setCredentials({...currentAtelier,
                    dateDebut: currentAtelier.date_debut ? formatDateTimeJs(currentAtelier.date_debut): null,
                    dateFin: currentAtelier.date_fin ? formatDateTimeJs(currentAtelier.date_fin): null});
            }
            setLoading(false);
        }

        fetchData();
        
    }, [currentAtelier] )

    useEffect( () => {
        if(addAtelierErrors){
               const apiErrors={};
               const violations=addAtelierErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [addAtelierErrors])

    useEffect( () => {
        if(addAtelierErrorsDesc){
            swal("Impossible d'ajouter l'atelier", addAtelierErrorsDesc, "error")
        }
      
      }, [addAtelierErrorsDesc])

    useEffect( () => {
        if(updateAtelierErrors){
               const apiErrors={};
               const violations=updateAtelierErrors;
               if(violations && Array.isArray(violations)){
                violations.forEach(({propertyPath, message}) => { 
                  apiErrors[propertyPath]= message;
                  } );
                  setErrors(apiErrors);
               }
                      
        }
      
      }, [updateAtelierErrors])

    useEffect( () => {
        if(updateAtelierErrorsDesc){
            swal("Impossible de mettre à jour l'atelier", updateAtelierErrorsDesc, "error")
        }
      
      }, [updateAtelierErrorsDesc])
 
    useEffect( () => {  

        if(currentEvenement){
               setEvent(currentEvenement); 
        } 

     }, [currentEvenement])

    //Récupération d'un event
    const fetchEvent= async id  => {
        try {
               await getEventById(id);
               if(!editing){
                setLoading(false);
               }
        } catch (error) {
               toast.error("Impossible de charger l'évenement");  
               Navigate("/");
        }
    }

    // Chargement de l'event au chargement du composent ou changement de l'id  
    useEffect( () => {
        setLoading(true);
        fetchEvent(id);
    }, [id])

    const onSignup = async (e) =>{
        e.preventDefault();

        setErrors({});
        clearMsgAddAtelier();

            if(editing){

                try {

                    let atelier= credentials;
                    await updateAtelier(atelier.id, atelier, currentEvenement);
                    //if response
                    setErrors({});
                    swal("L'atelier est à jour", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
                }

            } else{
                try {

                    let atelier= credentials;
                    await addAtelier(atelier, currentEvenement);
                    //if response
                    setErrors({});
                    swal("L'atelier est bien enregistré", "success");
                }   catch (err) {
                    toast.error("Une erreur est survenue !");
    
                }
            }

    }

    //Gestion des champs
    const handleChangeFields = ({currentTarget}) =>{
        const {value, name}= currentTarget;
        setCredentials({ ...credentials, [name]: value });
    }

    useLayoutEffect(() => {
        return () => {
               setErrors({});
               clearMsgAddAtelier();
               setEditing(false);
        }
    }, [])


    return (
        <>
            <div className="row page-titles">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><Link to={"/" + id + "/admin/ateliers"}> Ateliers</Link></li>
                    <li className="breadcrumb-item active"><Link to={"#"}> {editing ? "Modification": "Ajout"} </Link></li>
                </ol>
            </div>

            <div className="row tableRow ajoutInvite">
                <div className="col-lg-12">

                    <div className="card">

                        <div className="card-header">
                            <h4 className="card-title">
                            {editing ? "Modification | "
                            : "Ajout d'atelier"}

                            {editing && !loading && currentAtelier &&
                            currentAtelier.nom}

                             </h4>

                                <Link to={"/" + id + "/admin/ateliers"} className="btn btn-primary">
                                    <i class="fas fa-undo"  style={{ marginRight: "5px",}}></i>  
                                    Retour à la liste
                                </Link>

                        </div>

                    </div>

                </div>

                <div className='eventPage'>

        <div className="row">
                        <div className="col-lg-12">
                            <div className="card">

                                <div className="eventPageFragment">
                                    {!loading &&
                                    <Container>

                                        <Row>

                                            <Col md="12">
                                            <form onSubmit={onSignup}>
                                                
                                                <Row>

                                                <Col md="6">
                                                    <Field
                                                        name="nom"
                                                        value={credentials.nom}
                                                        onChange={handleChangeFields}
                                                        label= "Nom *"
                                                        placeholder="Nom"
                                                        error={errors.nom}
                                                        type="text"
                                                        required="true" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="description"
                                                        value={credentials.description}
                                                        onChange={handleChangeFields}
                                                        label= "Déscription"
                                                        placeholder="Déscription"
                                                        error={errors.description}
                                                        type="text"
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="dateDebut"
                                                        value={credentials.dateDebut}
                                                        onChange={handleChangeFields}
                                                        label= "Date de début *"
                                                        placeholder="Date de début *"
                                                        error={errors.dateDebut}
                                                        type="datetime-local" 
                                                        />
                                                </Col>

                                                <Col md="6">
                                                    <Field
                                                        name="dateFin"
                                                        value={credentials.dateFin}
                                                        onChange={handleChangeFields}
                                                        label= "Date fin *"
                                                        placeholder="Date fin *"
                                                        error={errors.dateFin}
                                                        type="datetime-local"
                                                        />
                                                </Col>

                                                </Row>

                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-lg"
                                                    >
                                                        {editing ? "Enregistrer" : "Ajouter"}
                                                    </button>
                                                </div>
                                            </form>
                                            </Col>

                                        </Row>

                                    </Container>
                                    }
                                    
                                    
                                </div>

                            </div>
                        </div>
        </div>

        </div>

            </div>

        </>
    );
};

const mapStateToProps = (state) => {
    return {
        evenements: state.evenementState.evenements,
        currentEvenement: state.evenementState.currentEvenement,
        addAtelierErrors: state.atelierState.addAtelierErrors,
        addAtelierErrorsDesc: state.atelierState.addAtelierErrorsDesc,
        updateAtelierErrors: state.atelierState.updateAtelierErrors,
        updateAtelierErrorsDesc: state.atelierState.updateAtelierErrorsDesc,
        userId: state.auth.user.id,
        currentAtelier: state.atelierState.currentAtelier
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { getEventById, 
        addAtelier, 
        updateAtelier, 
        clearMsgAddAtelier, 
        getAtelierById},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Atelier); 