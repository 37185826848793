export const formatDateTime = (date) => {
    return "le " + date.slice(8,10) + "/" + date.slice(5,7) + "/" + date.slice(0,4) + " à " + date.slice(11,16); 
}

export const formatDateTimeAtelier = (dateDebut, dateFin) => {
    return "- le " + dateDebut.slice(8,10) + "/" + dateDebut.slice(5,7) + "/" + dateDebut.slice(0,4) + " - " + dateDebut.slice(11,16)
    + " - " + dateFin.slice(11,16); 
}

export const formatDate = (date) => {
    return "le " + date.slice(8,10) + "/" + date.slice(5,7) + "/" + date.slice(0,4); 
}

export const formatDateEvent = (dateDebut, dateFin) => {
    return "Du " + dateDebut.slice(8,10) + "/" + dateDebut.slice(5,7) + "/" + dateDebut.slice(0,4) +
    " au " + dateFin.slice(8,10) + "/" + dateFin.slice(5,7) + "/" + dateFin.slice(0,4)
    ; 
}

export const formatDateStat = (date) => {
    return date.slice(8,10) + "/" + date.slice(5,7); 
}

export const formatDateJs = (date) => {
    
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const formatDateTimeJs = (dateTime) => {
    const d = new Date(dateTime);

    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);

    const hours = ('0' + d.getHours()).slice(-2);
    const minutes = ('0' + d.getMinutes()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}


export const yesterdayDate = () => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    let dd = yesterday.getDate();
    let mm = yesterday.getMonth()+1;
    let yyyy = yesterday.getFullYear();

    if(dd<10){dd='0'+dd} if(mm<10){mm='0'+mm}

    return  yyyy + "-" + mm + "-" + dd;

};