import React,{Fragment, useContext, useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {Link, useLocation} from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import {Dropdown} from "react-bootstrap";
//import HomeTabChart from './../Karciz/Dashboard/HomeTabChart';
import {letestBlog, recentEvent} from '../Karciz/Dashboard/HomePageData';
import DropdownBlog from '../Karciz/Dropdown/DropdownBlog';
import { ThemeContext } from '../../../context/ThemeContext';
import { getCountInvites, 
	getCountCheckedInvites,
	getCountPaidInvites,
	getCountPaidRedInvites,
	getCountOrganisateurs, 
	getCountOrateurs, 
	getCountSponsors, 
	getCountPresses,
	getCountSpecialites,
	getBetterSpecialites,
	getBetterVilles,
	getStatInscription } from '../../../store/actions/StatisticActions';
import ReportPieChart3 from '../../pages/Reports/ReportPieChart3';
import ReactApexChart from 'react-apexcharts';
import { Line, Pie } from 'react-chartjs-2';
import { formatDateJs, formatDateStat, yesterdayDate } from '../../../helpers/FormatHelpers';
import { getEventById } from '../../../store/actions/EvenementActions';

const SalesChart = loadable(() =>
	pMinDelay(import("../Karciz/Dashboard/SalesChart"), 1000)
);
const IncreaseChart = loadable(() =>
	pMinDelay(import("../Karciz/Dashboard/IncreaseChart"), 1000)
);
const IncomeChart = loadable(() =>
	pMinDelay(import("../Karciz/Dashboard/IncomeChart"), 1000)
);
const Doughnutchart = loadable(() =>
	pMinDelay(import("../Karciz/Dashboard/Doughnutchart"), 1000)
);
const SellingApexChart = loadable(() =>
	pMinDelay(import("../Karciz/EventPage/SellingApexChart"), 1000)
);

const HomeSalesRevenueChart = loadable(() =>
	pMinDelay(import("../Karciz/Dashboard/HomeSalesRevenueChart"), 1000)
);

function Home({
	getCountInvites, 
	countInvites,
	getCountCheckedInvites,
	countCheckedInvites,
	countPaidInvites,
	countPaidRedInvites,
	getCountOrganisateurs,
	countOrganisateurs,
	getCountOrateurs,
	countOrateurs,
	getCountSponsors,
	countSponsors,
	getCountPresses,
	countPresses,
	getBetterSpecialites,
	getCountSpecialites,
	countSpecialites,
	betterSpecialites,
	betterVilles,
	getBetterVilles,
	getStatInscription,
	statInscription,
	getCountPaidInvites,
	getCountPaidRedInvites,
	currentEvenement,
	getEventById,
	currentUser
	}) {
	const { changeBackground       
     } = useContext(ThemeContext);

    const [statisticInvites, setStatisticInvites]= useState(0);
    const [perCheckedInvites, setPerCheckedInvites]= useState("0%");
    const [checkedInvites, setCheckedInvites]= useState(0);
    const [paidInvites, setPaidInvites]= useState(0);
    const [totalPaidInvites, setTotalPaidInvite]= useState(0);
    const [statisticOrg, setStatisticOrg]= useState(0);
    const [statisticOra, setStatisticOra]= useState(0);
    const [statisticSponsors, setStatisticSponsors]= useState(0);
    const [statisticPresses, setStatisticPresses]= useState(0);
    const [statisticCountSpecialites, setStatisticCountSpecialites]= useState(0);
    const [statisticSpecialites, setStatisticSpecialites]= useState(0);
    const [statisticVilles, setStatisticVilles]= useState(0);
    const [registredToday, setRegistredToday]= useState(0);
    const [registredTodayPos, setRegistredTodayPos]= useState();
    const [registredTodayNeg, setRegistredTodayNeg]= useState();

    const [optionsSpecialite, setOptionsSpecialites]= useState();
    const [seriesSpecialites, setSeriesSpecialites]= useState();
    const [colorSpecialites, setColorSpecialites]= useState([]);

	const [optionsVilles, setOptionsVilles]= useState();
    const [seriesVilles, setSeriesVilles]= useState();
    const [colorVilles, setColorVilles]= useState([]);

	const [optionsSignup, setOptionsSignups]= useState();
    const [dataSignup, setDataSignup]= useState();
    const [colorSignup, setColorSignup]= useState([]);

	const [optionsStatAll, setOptionsStatAll]= useState({
		plugins:{
			legend: false,
			responsive: true,
		},
      
      maintainAspectRatio: false,
   }
  );
    const [dataStatAll, setDataStatAll]= useState();
	
	const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    let {id}= params;

	const countTotalPaid = async (paidInvites, id) => {
		await getEventById(id);
	}

	useEffect(() => {
		if(id){
			getCountInvites(id);
			getCountCheckedInvites(id);
			getCountPaidInvites(id);
			getCountPaidRedInvites(id);
			getCountOrganisateurs(id);
			getCountOrateurs(id);
			getCountSponsors(id);
			getCountPresses(id);
			getBetterSpecialites(id);
			getCountSpecialites(id);
			getBetterVilles(id);
			getStatInscription(id);
		}
     }, [id]);

	 useEffect(() => {
		if(countInvites){
			setStatisticInvites(countInvites);
		}
     }, [countInvites]);

	 useEffect(() => {
		if(countCheckedInvites){
			if(countInvites){
				let per= countCheckedInvites * 100 / countInvites;
				setPerCheckedInvites(per +"%");
				setCheckedInvites(countCheckedInvites);
			}
		}
     }, [countCheckedInvites]);

	 useEffect(() => {
		if(countPaidInvites){
				 countTotalPaid(countPaidInvites, id);
				 setPaidInvites(countPaidInvites);
		}
     }, [countPaidInvites, countPaidRedInvites]);

	 useEffect(() => {
		if(currentEvenement && currentEvenement.tarif && paidInvites){
				let totalPaid= (paidInvites * currentEvenement.tarif) - countPaidRedInvites;
				setTotalPaidInvite(totalPaid); 
		} else {
				setTotalPaidInvite(0); 
		}
     }, [currentEvenement]);

	 useEffect(() => {
		if(countOrganisateurs){
			setStatisticOrg(countOrganisateurs);
		}
     }, [countOrganisateurs]);

	 useEffect(() => {
		if(countOrateurs){
			setStatisticOra(countOrateurs);
		}
     }, [countOrateurs]);

	 useEffect(() => {
		if(countSponsors){
			setStatisticSponsors(countSponsors);
		}
     }, [countSponsors]);

	 useEffect(() => {
		if(countPresses){
			setStatisticPresses(countPresses);
		}
     }, [countPresses]);

	 useEffect(() => {
		if(countSpecialites){
			setStatisticCountSpecialites(countSpecialites);
		}
     }, [countSpecialites]);

	 useEffect(() => {
		if(betterSpecialites){
			setStatisticSpecialites(betterSpecialites);

			let listeColor= ['rgb(0, 143, 251)', 'rgb(0, 227, 150)', 'rgb(254, 176, 25)', 'rgb(255, 69, 96)', 'rgb(119, 93, 208)'];
			let tmpLabel= [];
			let tmpColors= [];
			let tmpSeries= [];
			for(let i= 0; i< betterSpecialites.length; i++){
				if(betterSpecialites[i].specialite == ""){
					tmpLabel.push("Autre");
				} else{
					tmpLabel.push(betterSpecialites[i].specialite);
				}
				tmpColors.push(listeColor[i]);
				tmpSeries.push(betterSpecialites[i].number);
			}

			setColorSpecialites(tmpColors);

			setOptionsSpecialites({
				chart: {
					width: 220,
					height: 220,
					type: 'donut',
					sparkline: {
						enabled: true,
					},
				},
				labels: tmpLabel,
				fill : {tmpColors},
				plotOptions: {
					donutChart : {
						customScale: 1,
						donut: {
							size: '70%'						
						}
					}
				},
				dataLabels: {
					enabled: false
				},
				responsive: [{
					breakpoint: 1300,
					options: {
						chart: {
							width: 120,
							height: 120
						},
					}
				}],
				
			 });

			 setSeriesSpecialites(tmpSeries);
		}
     }, [betterSpecialites]);

	 useEffect(() => {
		if(betterVilles){
			setStatisticVilles(betterVilles);

			let listeColor= ['rgb(0, 143, 251)', 'rgb(0, 227, 150)', 'rgb(254, 176, 25)', 'rgb(255, 69, 96)', 'rgb(119, 93, 208)'];
			let tmpLabel= [];
			let tmpColors= [];
			let tmpSeries= [];
			for(let i= 0; i< betterVilles.length; i++){
				if(betterVilles[i].wilaya == ""){
					tmpLabel.push("Autre");
				} else{
					tmpLabel.push(betterVilles[i].wilaya);
				}
				tmpColors.push(listeColor[i]);
				tmpSeries.push(betterVilles[i].number);
			}

			setColorVilles(tmpColors);

			setOptionsVilles({
				chart: {
					width: 220,
					height: 220,
					type: 'donut',
					sparkline: {
						enabled: true,
					},
				},
				labels: tmpLabel,
				fill : {tmpColors},
				plotOptions: {
					donutChart : {
						customScale: 1,
						donut: {
							size: '70%'						
						}
					}
				},
				dataLabels: {
					enabled: false
				},
				responsive: [{
					breakpoint: 1300,
					options: {
						chart: {
							width: 120,
							height: 120
						},
					}
				}],
				
			 });

			 setSeriesVilles(tmpSeries);
		}
     }, [betterVilles]);

	useEffect(() => {
		if(statInscription){

			setOptionsSignups({
				plugins:{		  
					legend: {
						display:false
					}
				},
				scales: {
				  y: 
					{
					   min: 0,	
					  max: 100,
					  ticks: {
						beginAtZero: true,              
						padding: 0,
					  },
					},
				  
				  x: 
					{
					  ticks: {
						padding: 0,
					  },
					  gridLines: {
						display: false,
						drawBorder: false,
					  },
					},
				  
				}
			})

		let tmpLabel =[];
		let tmpSeries= [];
		for(let i= 0; i< statInscription.length; i++){
			tmpLabel.push(formatDateStat(statInscription[i].date_inscription))
			tmpSeries.push(statInscription[i].number);
		}

		setDataSignup({
			defaultFontFamily: "Poppins",
			labels: tmpLabel,
			datasets: [
			  {
				label: "",
				data: tmpSeries,
				borderColor: "rgba(19,180,151,1)",
				borderWidth:  "2",
				// pointBackgroundColor: "rgba(64, 24, 157, 1)",
				backgroundColor: "rgba(19, 180, 151, 0)",
				tension:0.4
			  },
			],
		  })

		}

		if(statInscription){ 

			let tmpRegistredToday=  statInscription[statInscription.length-1];
			let tmpRegistredYesterday= statInscription[statInscription.length-2];


			if(tmpRegistredToday){

				if(tmpRegistredToday.date_inscription == formatDateJs(new Date())){

					if(tmpRegistredToday.number){
						setRegistredToday(tmpRegistredToday.number);
						let yesterday=  yesterdayDate();
						if(tmpRegistredYesterday){
							if(tmpRegistredYesterday.date_inscription == yesterday){
								let tmpNumberYst= tmpRegistredYesterday.number;
								
								if(tmpNumberYst < tmpRegistredToday.number){
									setRegistredTodayPos(tmpRegistredToday.number-tmpNumberYst);
								}else {
									setRegistredTodayNeg(tmpNumberYst-tmpRegistredToday.number);
								}
							}
						}
						
					}
					
				}

			}
		}

     }, [statInscription]);

	 useEffect(() => {
		if(statisticInvites && statisticOra && statisticOrg){
			setDataStatAll({
				datasets: [
				   {
					  data: [statisticInvites, statisticOra, statisticOrg],
					  borderWidth: 0,
					  backgroundColor: [
						"rgba(19, 180, 151, 1)",
						"rgba(19, 180, 151, 0.7)",
						"rgba(19, 180, 151, 0.5)"
					  ],
					  hoverBackgroundColor: [
						"rgba(19, 180, 151, 1)",
						"rgba(19, 180, 151, 0.7)",
						"rgba(19, 180, 151, 0.5)"
					  ],
				   },
				],
				labels: ["CONGRESSISTES", "ORATEURS", "ORGANISATEURS"],
			 })
		}
     }, [statisticInvites, statisticOra, statisticOrg]);
	
	return(
		<Fragment>
			<div className="row">
			
				<div className="row">	

					<div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6 bg-visiteurs'>
							<div className='widget-stat card bg-secondary'>
								<div className='card-body p-4'>
									<div className='media'>
										<span className='me-3' style={{background: "#63C6B1"}}>
										<i className='la la-graduation-cap'></i>
										</span>
										<div className='media-body text-white mb-2'>
											<p className='mb-1'>congressistes</p>
											<h3 className='text-white'>{statisticInvites}</h3>
											{checkedInvites ? <Link to={location.pathname + "/visiteurs"} className="text-white">{checkedInvites + " validés"} <i className="las la-long-arrow-alt-right scale5 ms-3"></i></Link> : null}
												<div className="progress" style={{height:"15px"}}>
													<div className="progress-bar-striped progress-bar-animated" style={{width: perCheckedInvites, height:"15px"}} role="progressbar" />
												</div>
										</div>
									</div>
								</div>
							</div>
					</div>

					{currentUser && currentUser.roles[0]=="ROLE_ADMIN" && 
					<div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6 bg-recette'>
						<div className='widget-stat card bg-secondary'>
								<div className='card-body p-4'>
									<div className='media ai-icon'>
										<span className='me-3 bgl-danger text-danger'>
										<svg
											id='icon-revenue'
											xmlns='http://www.w3.org/2000/svg'
											width='30'
											height='30'
											viewBox='0 0 24 24'
											fill='none'
											stroke='currentColor'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
											className='feather feather-dollar-sign'
										>
											<line x1='12' y1='1' x2='12' y2='23'></line>
											<path d='M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6'></path>
										</svg>
										</span>
										<div className='media-body text-white'>
										<p className='mb-1'>Recette</p>
										<h4 className='mb-0 text-white'>{totalPaidInvites} DA</h4>
										{countPaidInvites && countPaidInvites>1 ? <p className="text-white">{countPaidInvites + " paiements"}</p> : null}
										</div>
									</div>
								</div>
							</div>
					</div>
					}
					

					<div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
						<div className='widget-stat card bg-primary'>
							<div className='card-body  p-4'>
								<div className='media'>
									<span className='me-3'>
									<i class="fas fa-user-tie"></i>
									</span>
										<div className='media-body text-white text-end'>
											<p className='mb-1'>Organisateurs</p>
											<h3 className='text-white'>{statisticOrg}</h3>
											<Link to={location.pathname + "/organisateurs"} className="text-white">En savoir plus<i className="las la-long-arrow-alt-right scale5 ms-3"></i></Link>
										</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
							<div className='widget-stat card bg-success'>
								<div className='card-body p-4'>
									<div className='media'>
										<span className='me-3'>
										<i class="fas fa-chalkboard-teacher"></i>	
										</span>
										<div className='media-body text-white text-end'>
											<p className='mb-1'>Orateurs</p>
											<h3 className='text-white'>{statisticOra}</h3>
											<Link to={location.pathname + "/orateurs"} className="text-white">En savoir plus<i className="las la-long-arrow-alt-right scale5 ms-3"></i></Link>
										</div>
									</div>
								</div>
							</div>
					</div>

					<div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
						<div className='widget-stat card bg-info'>
							<div className='card-body p-4'>
								<div className='media'>
									<span className='me-3'>
									<i class="fas fa-hands-helping"></i>
									</span>
									<div className='media-body text-white text-end'>
										<p className='mb-1'>Sponsors</p>
										<h3 className='text-white'>{statisticSponsors}</h3>
										<Link to={location.pathname + "/sponsors"} className="text-white">En savoir plus<i className="las la-long-arrow-alt-right scale5 ms-3"></i></Link>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
						<div className='widget-stat card bg-secondary'>
							<div className='card-body  p-4'>
								<div className='media'>
									<span className='me-3'>
									<i class="fas fa-microphone-alt"></i>
									</span>
									<div className='media-body text-white text-end'>
										<p className='mb-1'>Presses</p>
										<h3 className='text-white'>{statisticPresses}</h3>
										<Link to={location.pathname + "/presses"} className="text-white">En savoir plus<i className="las la-long-arrow-alt-right scale5 ms-3"></i></Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					{currentUser && currentUser.roles[0]!="ROLE_ADMIN" &&
						<div className='col-xl-4 col-xxl-4 col-lg-6 col-sm-6'>
							<div className='widget-stat card' style={{background: "grey"}}>
								<div className='card-body  p-4'>
										<div className='media'>
											<span className='me-3' style={{background: "#999797", color: "white"}}>
												<i class="fas fa-stethoscope"></i>
											</span>
											<div className='media-body text-white text-end'>
												<p className='mb-1'>Spécialités</p>
												<h3 className='text-white'>{statisticCountSpecialites}</h3>
											</div>
										</div>
								</div>
							</div>
						</div>
					}

				</div>



					<div className="col-xl-6 col-md-6 statisticSpec">
						<div className="card">
						{currentUser && currentUser.roles[0]=="ROLE_ADMIN" &&
							<div className='col-xl-12'>
								<div className='widget-stat card' style={{background: "grey"}}>
									<div className='card-body  p-4'>
										<div className='media'>
											<span className='me-3' style={{background: "#999797", color: "white"}}>
												<i class="fas fa-stethoscope"></i>
											</span>
											<div className='media-body text-white text-end'>
												<p className='mb-1'>Spécialités</p>
												<h3 className='text-white'>{statisticCountSpecialites}</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						}	
							<div className="chart-deta d-flex align-items-center flex-wrap">
										{statisticSpecialites && statisticSpecialites.map((item, ind)=>(
											<div className="mb-2 d-flex me-3" key={ind}>
												<i className="fa fa-stop" style={{color: colorSpecialites[ind] }}></i> 
												<div className="dots-text">
													<p className="fs-14 mb-0">
														<strong>{item.number} </strong>
														{item.specialite != "" ? item.specialite: "Non renseigné"} 
													</p>
												</div>
											</div>
										))}                               
							</div>
							<div className="card-body d-flex">
							
								{optionsSpecialite && seriesSpecialites &&
									<ReactApexChart
									options={optionsSpecialite}
									series={seriesSpecialites}
									type="donut"
									/>
								}
							</div>
                    	</div>

            		</div>

					<div className="col-xl-6 col-md-6 statisticSpec">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Wilayas</h4>
							</div>
							<div className="chart-deta d-flex align-items-center flex-wrap">
										{statisticVilles && statisticVilles.map((item, ind)=>(
											<div className="mb-2 d-flex me-3" key={ind}>
												<i className="fa fa-stop" style={{color: colorSpecialites[ind] }}></i> 
												<div className="dots-text">
													<p className="fs-14 mb-0">
														<strong>{item.number} </strong>
														{item.wilaya != "" ? item.wilaya: "Autre"} 
													</p>
												</div>
											</div>
										))}                               
							</div>
							<div className="card-body d-flex">
							
								{optionsVilles && seriesVilles &&
									<ReactApexChart
									options={optionsVilles}
									series={seriesVilles}
									type="donut"
									/>
								}
							</div>
                    	</div>

            		</div>

					<div className="col-xl-6 col-md-6 ">

						{statInscription && statInscription.length >0 &&
							<div className="col-xl-12 col-md-12 statisticSpec">

								{optionsSignup && dataSignup &&
									<Line
										data={dataSignup}
										options={optionsSignup}
										height={150}
									/>
								}

							</div>
						}

						{registredToday ? 
							<div className="col-xl-12 col-md-12 statisticSpec diffStatistics">

								<div className='widget-stat card mt-4'>
									<div className='card-body p-4'>
										<div className='media ai-icon'>
											<span className='me-3 bgl-primary text-primary'>
												<i className='la la-graduation-cap'></i>
											</span>
											<div className='media-body'>
												<p className='mb-1'>Inscrits aujourd'hui</p>
												<h4 className='mb-0'>{registredToday}</h4>
												{registredTodayPos ?
													<span className='badge badge-success'>{ "+" +  registredTodayPos}</span>
												:
													registredTodayNeg && 
													<span className='badge badge-danger'>{"-" + registredTodayNeg}</span>
												}
												
											</div>
										</div>
									</div>
								</div>

							</div>
							:
							null
						}

					</div>

						{dataStatAll && optionsStatAll &&
							<div className="col-xl-6 col-md-6 mt-5 statisticSpec statisticRepar">
								<div className="card-header">
									<h4 className="card-title">Répartition</h4>
								</div>
								<div id="chart">
									<Pie data={dataStatAll} options={optionsStatAll} height={200} />
								</div>
							</div>
						}

			</div>
				
		</Fragment>
	)
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: "",
		countInvites: state.statisticState.countInvites,
		countCheckedInvites: state.statisticState.countCheckedInvites,
		countPaidInvites: state.statisticState.countPaidInvites,
		countPaidRedInvites: state.statisticState.countPaidRedInvites,
		countOrganisateurs: state.statisticState.countOrganisateurs,
		countOrateurs: state.statisticState.countOrateurs,
		countSponsors: state.statisticState.countSponsors,
		countPresses: state.statisticState.countPresses,
		countSpecialites: state.statisticState.countSpecialites,
		betterSpecialites: state.statisticState.betterSpecialites,
		betterVilles: state.statisticState.betterVilles,
		statInscription: state.statisticState.statInscription,
		currentEvenement: state.evenementState.currentEvenement,
        currentUser: state.auth.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {   getCountInvites,
			getCountCheckedInvites,
			getCountPaidInvites,
			getCountPaidRedInvites,
			getCountOrganisateurs,
			getCountOrateurs,
			getCountSponsors,
			getCountPresses,
			getBetterSpecialites,
			getCountSpecialites,
			getBetterVilles,
			getStatInscription,
			getEventById
		},
        dispatch,
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(Home); 
