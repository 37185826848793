import Axios from "axios";
import authHeader from "../../helpers/auth-header";
import { INSCRIPTIONS_API, INSCRIPTION_ATELIERS_API, SEND_EMAIL_INVITE } from "../../services/config";

export const ADD_INSCRIPTION_FAIL= "ADD_INSCRIPTION_FAIL";
export const UPDATE_INSCRIPTION_FAIL= "UPDATE_INSCRIPTION_FAIL";
export const ADD_INSCRIPTION_SUCCESS= "ADD_INSCRIPTION_SUCCESS";
export const UPDATE_INSCRIPTION_SUCCESS= "UPDATE_INSCRIPTION_SUCCESS";

export const addInscriptionByInvite = (invite, evenement) => (dispatch) => {

  let dateInscription= new Date().toLocaleString("en-US", {timeZone: "Africa/Algiers"});
  let inscription= {
    "invite": "/api/invites/" + invite.id,
    "evenement": "/api/evenements/" + evenement.id,
    "dateInscription": dateInscription
  };

  return Axios.post(INSCRIPTIONS_API, inscription).then(
    (data) => {

      dispatch({
        type: ADD_INSCRIPTION_SUCCESS,
        currentInscription: data.data
      });
      
      dispatch({
        type: ADD_INSCRIPTION_FAIL,
        addinscriptionErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      if(violations){

        dispatch({
          type: ADD_INSCRIPTION_FAIL,
          addinscriptionErrors: violations
                });
                throw new Error(error);
      }else{
        throw new Error(error)
      }
     
    }    
  );

};


export const addInscriptionByAdmin = (invite, checked, evenement, userId, paid) => (dispatch) => {

  let dateInscription= new Date().toLocaleString("en-US", {timeZone: "Africa/Algiers"});
  
  let inscription= {
    "invite": "/api/invites/" + invite.id,
    "evenement": "/api/evenements/" + evenement.id,
    "dateInscription": dateInscription,
    "user": "/api/users/"+ userId,
    "checked": checked === "true" ? true: false,
    "paid": paid === "true" ? true: false,
    "dateValidation": checked === "true" ? dateInscription  : null
  };


  return Axios.post(INSCRIPTIONS_API, inscription, {headers: authHeader()}).then(
    (data) => {

      dispatch({
        type: ADD_INSCRIPTION_SUCCESS,
        currentInscription: data.data
              });

      dispatch({
        type: ADD_INSCRIPTION_FAIL,
        addInscriptionErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      if(violations){

        dispatch({
          type: ADD_INSCRIPTION_FAIL,
          addInscriptionErrors: violations
                });
                throw new Error(error);
      }else{
        throw new Error(error)
      }
     
    }    
  );

};


export const updateCheckInscription = (idInvite, idInscription, checked, paid) => (dispatch) => {

  let dateValidation;
  let inscription;
  if(checked){
    dateValidation= new Date().toLocaleString("en-US", {timeZone: "Africa/Algiers"});
    inscription= {
      "dateValidation": dateValidation,
      "checked": true,
      "paid": paid
    };
  }else {
    inscription= {
      "dateValidation": null,
      "checked": false,
      "paid": paid
    };
  }
  

  return Axios.put(INSCRIPTIONS_API + "/" + idInscription, inscription, {headers: authHeader()}).then(
    (data) => {

      dispatch({
        type: UPDATE_INSCRIPTION_SUCCESS,
        idInvite: idInvite,
        updatedInscription: data.data,
        checked
              });

      dispatch({
        type: UPDATE_INSCRIPTION_FAIL,
        updateInscriptionErrors: null
              });
      return Promise.resolve();
    },
    (error) => {
      
      const message =error.response.data;
      const {violations} = message;
      if(violations){

        dispatch({
          type: UPDATE_INSCRIPTION_FAIL,
          updateInscriptionErrors: violations
                });
                throw new Error(error);
      }else{
        throw new Error(error)
      }
     
    }    
  );

};

export const updateStatusInscriptionByInvite = (idInscription, ateliers) => (dispatch) => {

  const requestData = {
    idInscription: idInscription,
    ateliers: ateliers
  };

  return Axios.post(INSCRIPTION_ATELIERS_API, requestData).then(
    (data) => {
      
      dispatch({
        type: UPDATE_INSCRIPTION_FAIL,
        updateInscriptionErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      if(violations){

        dispatch({
          type: UPDATE_INSCRIPTION_FAIL,
          updateInscriptionErrors: violations
                });
                throw new Error(error);
      }else{
        throw new Error(error)
      }
     
    }    
  );

};

export const updateStatusInscription = (idInscription, inscription) => (dispatch) => {

  return Axios.put(INSCRIPTIONS_API + "/" + idInscription, inscription, {headers: authHeader()}).then(
    (data) => {
      
      dispatch({
        type: UPDATE_INSCRIPTION_FAIL,
        updateInscriptionErrors: null
              });
      return Promise.resolve();
    },
    (error) => {

      const message =error.response.data;
      const {violations} = message;
      if(violations){

        dispatch({
          type: UPDATE_INSCRIPTION_FAIL,
          updateInscriptionErrors: violations
                });
                throw new Error(error);
      }else{
        throw new Error(error)
      }
     
    }    
  );

};

export const sendBadgeEmail = (idInscription) => (dispatch) => {
  
  let formData = new FormData();
  formData.append('idInscription', idInscription);

  return Axios.post(SEND_EMAIL_INVITE, formData, {headers: authHeader()}).then(
    (data) => {
      
      return Promise.resolve();
    },
    (error) => {

        throw new Error(error)
    }    
  );
}